import Bg from './components/bg';
import Overlay from './components/overlay';
import Main from './components/main';

function App() {
  return (
    <div id='wrapper'>
      <Bg />
      <Overlay />
      <Main />
    </div>
  );
}

export default App;
