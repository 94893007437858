

function Main() {
	return (
	  <div id="main">
		<header id="header">
					  <h1>imabee</h1>
						  <p>Developer &nbsp;&bull;&nbsp; DevOps &nbsp;&bull;&nbsp; Twitch Affiliate &nbsp;&bull;&nbsp; Buzzy Bee </p>
						  <nav>
							  <ul>
								  <li><a href="https://www.twitch.tv/imabee" class="icon fa-twitch"><span class="label">Twitch</span></a></li>
								  <li><a href="https://twitter.com/imabee_official" class="icon fa-x-twitter"><span class="label">X</span></a></li>
							  </ul>
						  </nav>
					  </header>
  
				  
					  <footer id="footer">
						  
					  </footer>
	  </div>
	);
  }
  
  export default Main;
  