


function Overlay() {
  return (
    <div id="overlay">
    </div>
  );
}

export default Overlay;
